@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
.slick-next:before {
  content: ">>" !important;
}
.slick-prev:before {
  content: "<<" !important;
}
body {
  background: rgb(97,130,181) !important;
  background: linear-gradient(180deg, rgba(97,130,181,1) 0%, rgba(78,106,146,1) 100%) !important;
  background-attachment: fixed !important;
  /** color: #EEEEEE; **/
  font-family: 'Open Sans', sans-serif;
}
.hexbg {
  background: url("assets/app/img/hex.png");
}
.blue-border {
  border: 5px solid #6283b2;
}

.b-prop {
  min-height: 100vh;
  margin-top: 35px;
  border-radius: 25px;
}

.b-border {
  border-right-color: rgba(255,255,255,.25) !important;
}
